.pill-list {
  display: flex;
  gap: 1.6rem;
  flex-wrap: wrap;
  padding: 0;
}

.pill-list__item {
  list-style-type: none;
}
