.details {
  padding: 0.5rem;
  border-radius: 1.3rem;
}

.details__summary {
  padding: 1rem;
  background-color: var(--details-summary-bg);
  border-radius: 1rem;
  transition: border-radius 0.2s;
}

.details[open] > .details__summary {
  border-radius: 1rem 1rem 0 0;
  margin-block-end: 0.5rem;
}

.details__summary::marker {
  color: var(--details-marker-colour);
  transition: all 0.2s;
}

.details[open] > .details__summary::marker {
  color: var(--details-marker-active-colour);
}

.details[open] > .details__content {
  animation: fadeIn 0.35s ease-in-out forwards;
}

.details__content {
  background-color: var(--details-content-bg);
  border-radius: 0 0 1rem 1rem;
  padding: 1rem;
  opacity: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
