:root {
  --exquisite-eggplant: rgb(50, 1, 47);
  --light-exquisite-eggplant: rgb(86 0 80);
  --dark-exquisite-eggplant: rgb(24, 0, 23);
  --terra-pin: rgb(82, 76, 66);
  --natural-choice: rgb(226, 223, 208);
  --butternut-squash: rgb(249, 115, 0);
  --june-bud: rgb(190, 215, 84);
  --fiery-future: rgb(187, 22, 22);
}
