h1,
.-h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--heading-weight);
  margin-block-end: 2.2rem;
}

h2,
.-h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--heading-weight);
  margin-block-end: 2.2rem;
}

h3,
.-h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--heading-weight);
  margin-block-end: 1.8rem;
}
