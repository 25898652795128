:root:has([data-theme='default']) {
  --page-background: var(--dark-exquisite-eggplant);
  --text-colour: var(--natural-choice);
  --link-colour: var(--butternut-squash);
  --link-interacted-colour: var(--natural-choice);
  --button-bg: var(--natural-choice);
  --button-interacted-colour: var(--butternut-squash);
  --button-interacted-bg: var(--terra-pin);
  --separator-colour: var(--terra-pin);
  --highlight-bg: var(--june-bud);
  --highlight-text: var(--terra-pin);
  --gradient-1: var(--exquisite-eggplant);
  --gradient-2: var(--dark-exquisite-eggplant);
  --h1-font-size: var(--font-size-3);
  --h2-font-size: var(--font-size-2);
  --h3-font-size: var(--font-size-1);
  --heading-weight: var(--extrabold-weight);
  --details-summary-bg: var(--light-exquisite-eggplant);
  --details-content-bg: var(--terra-pin);
  --details-marker-colour: var(--butternut-squash);
  --details-marker-active-colour: var(--terra-pin);
  --alert: var(--fiery-future);
  --spinner-detail: var(--june-bud);
  --spinner-border: var(--terra-pin);
  --rating-colour: var(--june-bud);
  @media (min-width: 77.8rem) {
    --h1-font-size: var(--font-size-4);
    --h2-font-size: var(--font-size-3);
    --h3-font-size: var(--font-size-2);
  }
}

[data-theme='transparent'] {
  --page-background: transparent;
  --text-colour: var(--natural-choice);
  --h1-font-size: var(--font-size-4);
  --link-colour: var(--june-bud);
  padding: 0;
  max-inline-size: none;
  font-size: var(--font-size-1);
  @media (min-width: 77.8rem) {
    font-size: var(--font-size-2);
  }
}
