.placeholder {
  text-shadow: 2px 2px black;
}

.placeholder__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  inset-block-start: 0;
}

.placeholder__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.4);
}

.placeholder__logo {
  max-inline-size: 130px;
  margin: 0 auto 65px;
  filter: brightness(0.9);
}

@media (width > 700px) {
  .placeholder__logo {
    max-inline-size: 200px;
    margin: 0 auto 25px;
  }
}

.placeholder__para {
  padding: 0 20px;
  text-align: center;
}

.placeholder__header {
  text-align: center;
  font-weight: 600;
  padding: 20px 0 0;
  font-size: var(--h1-font-size);
}

.placeholder__link {
  text-align: center;
}
